@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.Footer {
    background: $color-neutral-90;
    padding-top: 30px;

    h4 {
        color: $color-neutral-70;
        font-size: 12px !important;
        font-weight: 400;

        @include mobile {
            font-size: 14px !important;
        }
    }

    &-PoweredBy {
        padding-top: 24px;
    }

    .Accordion {
        .Accordion-Box_isOpen {
            .Accordion-Header {
                &::after {
                    filter: brightness(0) invert(1);

                    @include mobile {
                        top: 10px;
                    }
                }
            }
        }

        .Accordion-Header {
            &::after {
                @include mobile {
                    top: 4px;
                    right: 0;
                }
            }
        }

        .Accordion-Box {
            padding: 24px;
        }

        .Footer-Column:first-child {
            order: 2;
        }

        .Footer-Column:nth-child(2) {
            order: 3;
        }

        .Footer-Column:nth-child(3) {
            border-bottom: transparent;
            order: 4;
        }

        .Footer-Column:last-child {
            order: 1;
        }
    }

    &-CompanyInformationsLogo,
    &-CompanyInformationsAddress {
        width: 100%;
        justify-content: center;
    }

    &-CompanyInformations,
    &-CompanyInformationsEmail,
    &-CompanyInformationsAddress,
    &-CompanyInformationsLogo {
        display: flex;
    }

    &-CompanyInformationsIconWrapper {
        width: 22px;
    }

    &-CompanyInformationsAddress,
    &-CompanyInformationsEmail {
        gap: 18px;
    }

    &-CompanyInformationsAddress {
        padding-top: 25px;
    }

    &-CompanyInformationsEmail {
        padding-top: 36px;
        justify-content: center;

        h4 {
            width: 135px;
        }
    }

    &-CompanyInformations {
        flex-direction: column;
    }

    &-Column {
        &_isNewsletter {
            background: $color-neutral-5;
            padding: 30px 18px;

            &-ColumnTitle {
                font-size: 15px;
                line-height: 21px;
                margin-top: 0;
            }

            .Field-CheckboxLabelContent {
                font-size: 12px;
            }
        }
    }

    &-ColumnTitle {
        font-style: normal;
        font-weight: normal;
        color: $white;
        font-size: 20px;
        line-height: 27px;
    }

    &-ColumnSubtitle {
        font-size: 14px !important;
        line-height: 18px;
        color: $color-neutral-70;
        margin-bottom: 40px;
    }

    .CmsBlock-Wrapper {
        width: 100%;
    }

    &-ContactItem {
        color: $color-neutral-70;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.05em;
        padding: 18px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: left;

        svg {
            margin-right: 18px;
            max-width: 23px;
            max-height: 23px;
        }

        a {
            color: $color-neutral-70;
            text-decoration: none;
            font-weight: normal;
        }
    }

    &-Social {
        display: flex;
        padding: 49px 0;
        justify-content: space-between;

        @include mobile {
            padding-inline: 32px;
        }

        svg {
            @include mobile {
                width: 40px;
                height: 32px;
            }
        }
    }

    ul {
        li {
            padding: 0;
            margin-bottom: 30px;

            &::before {
                display: none;
            }

            &:last-of-type {
                margin-bottom: 7px;
            }
        }
    }

    a {
        color: $color-neutral-70;
        text-decoration: none;
        font-weight: normal;
        letter-spacing: 0.05em;
    }

    h3 {
        line-height: inherit;
        margin: 0;
        color: $white;
        font-size: 14px !important;

        @include mobile {
            font-size: 20px !important;
        }
    }

    &-CopyrightContent {
        padding: 56px 0;
        font-size: 12px;
        line-height: 18px;
        text-align: center;
        color: $color-neutral-70;

        a {
            font-size: 12px;
            line-height: 18px;
            letter-spacing: 0;
        }
    }

    &-Copyright,
    &-PoweredBy {
        display: block;
    }

    &-ScrollTopWrapper {
        position: relative;
    }

    &-ScrollTop {
        background: $color-neutral-80;
        width: 100%;
        height: 72px;
        cursor: pointer;

        svg {
            width: 40px;
            filter: brightness(0%);
        }
    }
}

@include tabletAndAbove {
    .Footer {
        .ContentWrapper {
            padding-left: 72px;
            padding-right: 72px;
        }

        &-ColumnSubtitle {
            margin-bottom: 16px;
        }

        &-CopyrightContent {
            display: flex;
            justify-content: space-between;
            text-align: left;
        }
    }
}

@include desktopAndAbove {
    .Footer {
        padding-top: 0;

        .ContentWrapper {
            padding-left: 0;
            padding-right: 0;

            @include page-container;
        }

        &-CompanyInformationsLogo {
            position: absolute;
            left: -60px;
            top: 20px;
            width: auto;
        }

        &-CompanyInformationsLogo,
        &-CompanyInformationsAddress {
            width: 100%;
            justify-content: flex-start;
        }

        &-Copyright {
            padding-top: 24px;
        }

        &-Columns {
            display: flex;
            justify-content: space-between;
        }

        &-ColumnTitle {
            font-size: 14px;
            line-height: 21px;
            margin: 0 0 16px;
            letter-spacing: 0.05em;
        }

        &-Accordion {
            display: flex;
            flex-direction: row;
            padding: 88px 25px 80px 0;
            justify-content: space-around;

            .Footer-Column {
                padding: 0;
            }

            .Footer-Column:last-child {
                order: 4 !important;
            }
        }

        ul {
            li {
                margin-bottom: 16px;
            }
        }

        a {
            font-size: 12px;
            line-height: 18px;
        }

        &-CompanyInformationsEmail {
            padding-top: 36px;
            justify-content: flex-start;
        }

        &-CopyrightContent {
            padding: 40px 0;
            border-top: 1px solid $color-neutral-80;
            font-size: 14px;
            line-height: 21px;
            letter-spacing: 0.05em;

            a {
                font-size: 14px;
                line-height: 21px;
                letter-spacing: 0.05em;
            }
        }

        &-ScrollTop {
            position: absolute;
            width: 40px;
            height: 40px;
            right: 5px;
            top: 0;
            z-index: 1;
            box-sizing: content-box;
            border: 10px solid var(--footer-background);

            svg {
                width: 15px;
            }
        }
    }
}

@include above1200 {
    .Footer {
        &-Accordion {
            padding: 88px 88px 80px 0;
            justify-content: space-between;
        }
    }
}

@include above1570 {
    .Footer {
        &-ScrollTop {
            right: -73px;
        }
    }
}
