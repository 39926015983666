@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.TierPrices {
    margin-bottom: 32px;

    @include mobile {
        margin-bottom: 24px;
    }

    &-Item {
        font-size: 16px;
        margin-bottom: 12px;
    }
}
