@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.StoreSwitcher {
    @include mobile {
        padding: 14px 30px 14px 32px;
        margin: 0;
    }

    &-StoreList {
        background-color: var(--store-switcher-list-background);
        border: 1px solid var(--input-border-color);
        display: none;
        inset-inline-end: auto;
        position: absolute;
        inset-block-start: 25px;
        inset-inline-start: -20px;
        z-index: 2;

        @include mobile {
            inset-block-start: 48px;
        }

        &_isOpen {
            display: block;
        }
    }
}
