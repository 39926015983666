@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.DiscountLabel {
    color: $white;
    background: $color-primary-50;
    padding: 2px 3px 3px;
    font-weight: 500;
    margin-bottom: 10px;
    margin-top: 4px;
    font-size: 14px;

    &_secondary {
        background: $color-secondary-50;
        padding: 6px 12px;
        font-size: 12px;
        display: block;
        margin-top: 0;
        text-align: center;
    }
}
