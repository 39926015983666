@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.AnnouncementNotice {
    padding: 0;
    color: $white;
    background-color: $black !important;

    &-Content {
        text-align: center;
        font-size: 12px;
        letter-spacing: 0.7px;
        line-height: 18px;
        display: flex;
        justify-content: center;
        align-items: center;

        li,
        a {
            color: $white;
            font-size: 12px;
            letter-spacing: 0.7px;
            line-height: 18px;
        }

        li::before {
            content: none;
        }
    }

    &-Text {
        display: flex;
        flex-direction: row;
        align-items: center;

        ul {
            li {
                margin: 0;
            }
        }

        p {
            margin-bottom: 0;
            color: inherit;
            font-weight: inherit;
            font-size: inherit;
        }
    }
}
