@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.Header {
    &-TopMenu {
        @include page-container;

        display: flex;
        justify-content: flex-end;
        padding-top: 6px;

        .StoreSwitcher {
            &-StoreList {
                &_isOpen {
                    z-index: 102;
                }
            }
        }

        .CurrencySwitcher {
            height: 19px;

            .FieldSelect {
                &-Select {
                    height: 19px;
                }

                &-Options {
                    &_isExpanded {
                        border: 1px solid var(--primary-divider-color);
                        z-index: 102;
                        inset-block-start: 25px;
                    }
                }

                &-Option {
                    color: $black;
                }
            }
        }
    }

    &-Switcher {
        display: flex;
        align-items: center;
    }

    &-Menu {
        @include desktopAndAbove {
            padding-top: 2px;
        }
    }

    &-MinicartItemCount {
        line-height: 15px;
    }

    &-CompareCount,
    &-WishlistCount {
        position: absolute;
        inset-block-start: 9px;
        inset-inline-end: 9px;
        width: 14px;
        height: 14px;
        background: $color-primary-70;
        border-radius: 50%;
        font-size: 8px;
        color: $color-neutral-5;
        text-align: center;
        line-height: 15px;

        @include mobile {
            inset-block-start: -4px;
            inset-inline-end: 0;
        }

        @include tablet {
            inset-block-start: -4px;
            inset-inline-end: 9px;
        }
    }

    &-WishlistCount {
        @include mobile {
            inset-block-start: -6px;
        }

        @include desktop {
            inset-inline-end: 14px;
        }
    }

    .MobilePopup-Content {
        @include mobileAndTablet {
            .CmsBlock-Wrapper {
                &_type_infoBar {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    max-width: 100%;
                }
            }
        }
    }
}
