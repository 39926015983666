@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.Table {
    $parent: &;

    &-Table {
        table-layout: auto;
        width: 100%;
        margin-block-end: 0;
        border: 0;
    }

    &-Head {
        background-color: $color-other-7;
        color: $color-neutral-90;
        font-weight: 500;
        font-size: 14px;
    }

    &-Row {
        &_isActive {
            background: $color-other-6;
        }
    }
}
