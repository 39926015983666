.Banner {
    &_type_singleTile {
        overflow: hidden;

        &:hover {
            img {
                transform: scale(1.02);
            }
        }

        p {
            margin-bottom: 0;
            color: $color-neutral-5;
        }

        a {
            display: block;
            height: 100%;
        }

        img {
            display: block;

            @include default-transition(transform);
        }

        .Button {
            padding: 0;
            width: 170px;
        }
    }

    &_variant_short {
        .Banner {
            &-Title {
                text-align: center;
            }

            &-Bar {
                position: absolute;
                left: 0;
                width: 100%;
                bottom: 0;
                text-align: center;
                background: $color-neutral-90;
                padding: 24px 30px;

                button.Button {
                    font-size: 14px;
                }
            }

            &-Header {
                margin-bottom: 30px;
            }

            &-Image {
                display: block;
            }
        }
    }

    &_variant_wide {
        grid-column: 1 / -1;

        .Banner {
            &_type_singleTile {
                .Banner {
                    &-Title {
                        text-align: center;
                    }

                    &-Bar {
                        position: absolute;
                        left: 0;
                        width: 100%;
                        bottom: 0;
                        text-align: center;
                        background: $color-neutral-90;
                        padding: 30px 30px 24px;
                    }

                    &-Header {
                        margin-bottom: 20px;
                        font-size: 24px;
                    }
                }
            }
        }
    }

    &_layout_grid {
        .Banner-Image_list {
            display: none;
        }
    }

    &_layout_list {
        .Banner-Image_grid {
            display: none;
        }

        &.Banner {
            &_variant_short {
                .Banner {
                    &_type_singleTile {
                        .Banner {
                            &-Bar {
                                right: 0;
                                width: 445px;
                                height: 100%;
                                text-align: left;
                                left: auto;
                                display: flex;
                                align-items: center;
                                padding: 25px;
                            }

                            &-Header {
                                margin-bottom: 0;
                            }

                            .Button {
                                width: 188px;
                                flex-shrink: 0;
                                margin-left: 10px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@include mobile {
    .Banner {
        &_layout_grid {
            &.Banner {
                &_variant_short {
                    .Banner {
                        &-Bar {
                            padding: 14px;

                            button.Button {
                                font-size: 10px;
                                padding: 4px 15px;
                                height: 24px;
                                width: auto;
                            }
                        }

                        &-Header {
                            font-size: 12px;
                            line-height: 18px;
                            margin-bottom: 10px;
                        }
                    }
                }
            }
        }
    }
}

@include desktopAndAbove {
    .Banner {
        &_variant_wide {
            .Banner {
                &_type_singleTile {
                    .Banner {
                        &-Bar {
                            right: 0;
                            width: 334px;
                            height: 100%;
                            text-align: left;
                            padding: 0 58px;
                            left: auto;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                        }
                    }
                }
            }
        }
    }
}
