@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.ListIcon {
    @include rtl-icon;

    cursor: pointer;

    &_isActive {
        stroke: var(--primary-base-color);

        &:hover {
            stroke: var(--color-black);
        }
    }
}
