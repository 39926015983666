@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

:root {
    --switcher-width: 40px;
    --switcher-height: 20px;
    --switcher-border-color: #{$color-other-13};
    --switcher-background: #{$color-other-14};
    --switcher-active-border-color: #{$color-neutral-90};
    --switcher-active-background: #{$color-neutral-90};
    --switcher-hover-border-color: #{$color-primary-50};
    --switcher-hover-background: #{$white};

    --switcher-switch-width: 12px;
    --switcher-switch-height: 12px;
    --switcher-switch-background: #{$color-neutral-70};
    --switcher-switch-active-background: #{$white};
    --switcher-switch-hover-background: #{$color-primary-50};
}

.Switcher {
    &_isLoading {
        pointer-events: none;
    }

    .Field {
        margin-block: 0;

        &-CheckboxLabel {
            height: var(--switcher-height);
            width: var(--switcher-width);

            [type="checkbox"] {
                width: 100%;

                & + .input-control {
                    background: var(--switcher-background);
                    border-color: var(--switcher-border-color);

                    &::after {
                        border: none;
                        box-shadow: none;
                        height: var(--switcher-switch-height);
                        width: var(--switcher-switch-width);
                        left: 0;
                        top: 50%;
                        background: var(--switcher-switch-background);
                        transform: translate(4px, -50%);
                    }
                }

                &:checked + .input-control {
                    background: var(--switcher-active-background);
                    border-color: var(--switcher-active-border-color);

                    &::after {
                        background: var(--switcher-switch-active-background);
                        transform: translate(24px, -50%);
                    }
                }

                &:hover {
                    & + .input-control {
                        background: var(--switcher-hover-background);
                        border-color: var(--switcher-hover-border-color);

                        &::after {
                            background: var(--switcher-switch-hover-background);
                        }
                    }
                }
            }

            .input-control {
                flex: 1 0 40px;
                width: 40px;
                max-width: 40px;
                height: 100%;
                margin-inline: 0;
                border-color: var(--switcher-border-color);
            }
        }
    }
}
