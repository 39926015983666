@font-face {
    font-family: 'Saira';
    src: url('../../assets/fonts/Saira/Saira-Thin.eot');
    src: url('../../assets/fonts/Saira/Saira-Thin.eot?#iefix') format('embedded-opentype'),
        url('../../assets/fonts/Saira/Saira-Thin.woff2') format('woff2'),
        url('../../assets/fonts/Saira/Saira-Thin.woff') format('woff'),
        url('../../assets/fonts/Saira/Saira-Thin.ttf') format('truetype');
    font-style: normal;
    font-weight: 100;
}

@font-face {
    font-family: 'Saira';
    src: url('../../assets/fonts/Saira/Saira-ThinItalic.eot');
    src: url('../../assets/fonts/Saira/Saira-ThinItalic.eot?#iefix') format('embedded-opentype'),
        url('../../assets/fonts/Saira/Saira-ThinItalic.woff2') format('woff2'),
        url('../../assets/fonts/Saira/Saira-ThinItalic.woff') format('woff'),
        url('../../assets/fonts/Saira/Saira-ThinItalic.ttf') format('truetype');
    font-style: italic;
    font-weight: 100;
}

@font-face {
    font-family: 'Saira';
    src: url('../../assets/fonts/Saira/Saira-ExtraLight.eot');
    src: url('../../assets/fonts/Saira/Saira-ExtraLight.eot?#iefix') format('embedded-opentype'),
        url('../../assets/fonts/Saira/Saira-ExtraLight.woff2') format('woff2'),
        url('../../assets/fonts/Saira/Saira-ExtraLight.woff') format('woff'),
        url('../../assets/fonts/Saira/Saira-ExtraLight.ttf') format('truetype');
    font-style: normal;
    font-weight: 200;
}

@font-face {
    font-family: 'Saira';
    src: url('../../assets/fonts/Saira/Saira-ExtraLightItalic.eot');
    src: url('../../assets/fonts/Saira/Saira-ExtraLightItalic.eot?#iefix') format('embedded-opentype'),
        url('../../assets/fonts/Saira/Saira-ExtraLightItalic.woff2') format('woff2'),
        url('../../assets/fonts/Saira/Saira-ExtraLightItalic.woff') format('woff'),
        url('../../assets/fonts/Saira/Saira-ExtraLightItalic.ttf') format('truetype');
    font-style: italic;
    font-weight: 200;
}

@font-face {
    font-family: 'Saira';
    src: url('../../assets/fonts/Saira/Saira-Light.eot');
    src: url('../../assets/fonts/Saira/Saira-Light.eot?#iefix') format('embedded-opentype'),
        url('../../assets/fonts/Saira/Saira-Light.woff2') format('woff2'),
        url('../../assets/fonts/Saira/Saira-Light.woff') format('woff'),
        url('../../assets/fonts/Saira/Saira-Light.ttf') format('truetype');
    font-style: normal;
    font-weight: 300;
}

@font-face {
    font-family: 'Saira';
    src: url('../../assets/fonts/Saira/Saira-LightItalic.eot');
    src: url('../../assets/fonts/Saira/Saira-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('../../assets/fonts/Saira/Saira-LightItalic.woff2') format('woff2'),
        url('../../assets/fonts/Saira/Saira-LightItalic.woff') format('woff'),
        url('../../assets/fonts/Saira/Saira-LightItalic.ttf') format('truetype');
    font-style: italic;
    font-weight: 300;
}

@font-face {
    font-family: 'Saira';
    src: url('../../assets/fonts/Saira/Saira-Regular.eot');
    src: url('../../assets/fonts/Saira/Saira-Regular.eot?#iefix') format('embedded-opentype'),
        url('../../assets/fonts/Saira/Saira-Regular.woff2') format('woff2'),
        url('../../assets/fonts/Saira/Saira-Regular.woff') format('woff'),
        url('../../assets/fonts/Saira/Saira-Regular.ttf') format('truetype');
    font-style: normal;
    font-weight: 400;
}

@font-face {
    font-family: 'Saira';
    src: url('../../assets/fonts/Saira/Saira-Italic.eot');
    src: url('../../assets/fonts/Saira/Saira-Italic.eot?#iefix') format('embedded-opentype'),
        url('../../assets/fonts/Saira/Saira-Italic.woff2') format('woff2'),
        url('../../assets/fonts/Saira/Saira-Italic.woff') format('woff'),
        url('../../assets/fonts/Saira/Saira-Italic.ttf') format('truetype');
    font-style: italic;
    font-weight: 400;
}

@font-face {
    font-family: 'Saira';
    src: url('../../assets/fonts/Saira/Saira-Medium.eot');
    src: url('../../assets/fonts/Saira/Saira-Medium.eot?#iefix') format('embedded-opentype'),
        url('../../assets/fonts/Saira/Saira-Medium.woff2') format('woff2'),
        url('../../assets/fonts/Saira/Saira-Medium.woff') format('woff'),
        url('../../assets/fonts/Saira/Saira-Medium.ttf') format('truetype');
    font-style: normal;
    font-weight: 500;
}

@font-face {
    font-family: 'Saira';
    src: url('../../assets/fonts/Saira/Saira-MediumItalic.eot');
    src: url('../../assets/fonts/Saira/Saira-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('../../assets/fonts/Saira/Saira-MediumItalic.woff2') format('woff2'),
        url('../../assets/fonts/Saira/Saira-MediumItalic.woff') format('woff'),
        url('../../assets/fonts/Saira/Saira-MediumItalic.ttf') format('truetype');
    font-style: italic;
    font-weight: 500;
}

@font-face {
    font-family: 'Saira';
    src: url('../../assets/fonts/Saira/Saira-SemiBold.eot');
    src: url('../../assets/fonts/Saira/Saira-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('../../assets/fonts/Saira/Saira-SemiBold.woff2') format('woff2'),
        url('../../assets/fonts/Saira/Saira-SemiBold.woff') format('woff'),
        url('../../assets/fonts/Saira/Saira-SemiBold.ttf') format('truetype');
    font-style: normal;
    font-weight: 600;
}

@font-face {
    font-family: 'Saira';
    src: url('../../assets/fonts/Saira/Saira-SemiBoldItalic.eot');
    src: url('../../assets/fonts/Saira/Saira-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('../../assets/fonts/Saira/Saira-SemiBoldItalic.woff2') format('woff2'),
        url('../../assets/fonts/Saira/Saira-SemiBoldItalic.woff') format('woff'),
        url('../../assets/fonts/Saira/Saira-SemiBoldItalic.ttf') format('truetype');
    font-style: italic;
    font-weight: 600;
}

@font-face {
    font-family: 'Saira';
    src: url('../../assets/fonts/Saira/Saira-Bold.eot');
    src: url('../../assets/fonts/Saira/Saira-Bold.eot?#iefix') format('embedded-opentype'),
        url('../../assets/fonts/Saira/Saira-Bold.woff2') format('woff2'),
        url('../../assets/fonts/Saira/Saira-Bold.woff') format('woff'),
        url('../../assets/fonts/Saira/Saira-Bold.ttf') format('truetype');
    font-style: normal;
    font-weight: 700;
}

@font-face {
    font-family: 'Saira';
    src: url('../../assets/fonts/Saira/Saira-BoldItalic.eot');
    src: url('../../assets/fonts/Saira/Saira-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('../../assets/fonts/Saira/Saira-BoldItalic.woff2') format('woff2'),
        url('../../assets/fonts/Saira/Saira-BoldItalic.woff') format('woff'),
        url('../../assets/fonts/Saira/Saira-BoldItalic.ttf') format('truetype');
    font-style: italic;
    font-weight: 700;
}

@font-face {
    font-family: 'Saira';
    src: url('../../assets/fonts/Saira/Saira-ExtraBold.eot');
    src: url('../../assets/fonts/Saira/Saira-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('../../assets/fonts/Saira/Saira-ExtraBold.woff2') format('woff2'),
        url('../../assets/fonts/Saira/Saira-ExtraBold.woff') format('woff'),
        url('../../assets/fonts/Saira/Saira-ExtraBold.ttf') format('truetype');
    font-style: normal;
    font-weight: 800;
}

@font-face {
    font-family: 'Saira';
    src: url('../../assets/fonts/Saira/Saira-ExtraBoldItalic.eot');
    src: url('../../assets/fonts/Saira/Saira-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('../../assets/fonts/Saira/Saira-ExtraBoldItalic.woff2') format('woff2'),
        url('../../assets/fonts/Saira/Saira-ExtraBoldItalic.woff') format('woff'),
        url('../../assets/fonts/Saira/Saira-ExtraBoldItalic.ttf') format('truetype');
    font-style: italic;
    font-weight: 800;
}

@font-face {
    font-family: 'Saira';
    src: url('../../assets/fonts/Saira/Saira-Black.eot');
    src: url('../../assets/fonts/Saira/Saira-Black.eot?#iefix') format('embedded-opentype'),
        url('../../assets/fonts/Saira/Saira-Black.woff2') format('woff2'),
        url('../../assets/fonts/Saira/Saira-Black.woff') format('woff'),
        url('../../assets/fonts/Saira/Saira-Black.ttf') format('truetype');
    font-style: normal;
    font-weight: 900;
}

@font-face {
    font-family: 'Saira';
    src: url('../../assets/fonts/Saira/Saira-BlackItalic.eot');
    src: url('../../assets/fonts/Saira/Saira-BlackItalic.eot?#iefix') format('embedded-opentype'),
        url('../../assets/fonts/Saira/Saira-BlackItalic.woff2') format('woff2'),
        url('../../assets/fonts/Saira/Saira-BlackItalic.woff') format('woff'),
        url('../../assets/fonts/Saira/Saira-BlackItalic.ttf') format('truetype');
    font-style: italic;
    font-weight: 900;
}
