@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.ShowRetailPrices {
    &_isPDP {
        top: 0;
        left: 0;
        position: absolute;
    }

    &_isPLP {
        bottom: 6px;
        right: 0;
        position: absolute;
    }

    button {
        padding-left: 15px;
        padding-right: 15px;
        font-size: 12px;
        cursor: pointer;
    }
}
