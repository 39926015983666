.Banner {
    &_type_mosaic {
        margin-bottom: 30px;
        min-height: 300px;

        .Banner {
            &-Item {
                overflow: hidden;
                margin-bottom: 24px;

                a {
                    display: block;
                    height: 100%;
                }

                h3 {
                    margin: 0;
                }
            }

            &-Title {
                text-align: center;
            }
        }

        .Button {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: 32px;
            background: $black;
            color: $white;

            h1, h2, h3, h4, h5, h6 {
                font-weight: inherit;
                font-size: inherit;
                color: inherit;
            }
        }

        img {
            width: 100%;
            height: 100%;
            display: block;
        }
    }
}

@include mobile {
    .Banner {
        &_type_mosaic {
            .Banner {
                &-Title {
                    font-size: 24px;
                    line-height: 32px;
                }
            }

            .Button {
                bottom: 24px;
            }
        }
    }
}

@include mobileAndTablet {
    .Banner {
        &_type_mosaic {
            .Button {
                height: 47px;
                line-height: 47px;
                font-size: 12px;
            }
        }
    }
}

@include tabletAndAbove {
    .Banner {
        &_type_mosaic {
            .Banner {
                &-Column {
                    &:first-child {
                        .Banner-Item {
                            height: auto;
                        }
                    }

                    &:last-child {
                        margin-left: 24px;
                        
                        .Banner-Item {
                            height: 250px;
                        }
                    }
                }

                &-Row {
                    display: flex;
                    justify-content: space-between;
                }
            }

            img {
                transform: scale(1.001);
            }
        }
    }
}

@include desktopAndAbove {
    .Banner {
        &_type_mosaic {
            .Banner {
                &-Items {
                    display: flex;
                    align-items: flex-end;
                }

                &-Column {
                    &:first-child {
                        margin-right: 24px;
                    }

                    &:last-child {
                        .Banner-Item {
                            height: auto;
                        }
                    }
                }

                &-Row {
                    &:first-child {
                        margin-bottom: 24px;
                    }
                }

                &-Item {
                    margin-bottom: 0;

                    &:hover {
                        img {
                            transform: scale(1.02);
                        }
                    }
                }
            }

            img {
                min-width: 1px;
                min-height: 1px;

                @include default-transition(transform);
            }
        }
    }
}
