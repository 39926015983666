@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.KeyValueTable {
    border: 0;
    margin: 0;
    margin-block-end: 24px;
    min-width: 100%;
    font-size: 14px;

    tbody {
        tr:not(:last-child) {
            border-bottom: 1px solid $color-other-9;
        }

        th {
            color: $color-neutral-70;
        }
    }

    @include mobile {
        margin-block-end: 14px;
    }

    &-Heading {
        background-color: $color-other-7;
        color: $black;
        font-size: 14px;
        font-weight: 600;
    }

    &-Item {
        &-Name {
            border-block-start: 1px solid rgba(10, 10, 10, .5);
        }
    }

    &-Totals {
        &-Separated {
            border-block-start: 1px solid rgba(10, 10, 10, .5);
        }
    }

    &-Wrapper {
        @include mobile {
            overflow-x: auto;
        }
    }
}
