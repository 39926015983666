@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.BannerSlider {
    margin-bottom: 30px;
    margin-left: -20px;
    margin-right: -20px;
    min-height: 300px;

    &-Content {
        background: $black-transparent;
        position: absolute;
        bottom: 0;
        right: 0;
        padding: 16px 50px;
        width: 100%;
        text-align: center;

        h2,a {
            color: $white;
        }

        .Button {
            align-self: flex-start;
            border-color: $white;
        }
    }

    .TypographyHeader {
        margin-bottom: 19px;
        margin-top: 0;
    }

    .Button {
        display: inline-flex;
    }

    .slick-dots {
        position: relative;
        bottom: 0;
        margin-top: 15px;

        li {
            margin-left: 0;
            margin-right: 15px;
            font-size: 16px;
            color: $color-neutral-70;
            width: 32px;
            height: auto;

            &:last-child {
                margin-right: 0;
            }

            &::after {
                width: 32px;
                height: 3px;
                background: $color-neutral-40;
                content: '';
                position: absolute;
                left: 0;
                bottom: 0;
            }

            div {
                display: none;
            }

            &.slick-active {
                &::after {
                    background: $color-primary-50 !important;
                }
            }
        }
    }
}

@include mobile {
    .BannerSlider {
        .TypographyHeader {
            font-size: 24px;
            line-height: 32px;
        }
    }
}

@include tabletAndAbove {
    .BannerSlider {
        &-Content {
            padding: 0 50px 0 25px;
            max-width: 320px;
            text-align: left;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        .Button {
            align-self: flex-start;
        }

        .slick-dots {
            margin-top: 25px;

            li {
                margin-right: 24px;
            }
        }
    }
}

@include desktopAndAbove {
    .BannerSlider {
        margin-left: 0;
        margin-right: 0;

        &-Content {
            display: flex;
            max-width: 400px;
            padding: 50px 60px 46px;
        }

        .slick-dots {
            text-align: left;
            margin-top: 15px;

            li {
                width: auto;
                padding-bottom: 5px;
                margin-right: 56px;

                &::after {
                    @include default-transition(all, .2s);

                    width: 0;
                }

                div {
                    display: block;
                }

                &:not(.slick-active) {
                    &::after {
                        background: $color-neutral-40;
                    }
                }

                &.slick-active {
                    &::after {
                        width: 32px;
                    }
                }
            }
        }
    }
}

@include above1480 {
    .BannerSlider {
        .SliderNextArrow {
            right: -44px;
        }

        .SliderPrevArrow {
            left: -44px;
        }
    }
}
