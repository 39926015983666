@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.CookiePopup {
    @include mobile {
        inset-block-end: 10px;
    }

    @include desktop {
        padding: 6px 12px;
    }

    &-Content {
        &::after {
            display: none;
        }
    }

    &-Link {
        color: $color-primary-50;
        padding-inline-start: 6px;

        &:hover {
            color: $color-primary-40;
        }
    }

    &-CTA {
        color: $color-neutral-5;

        &:hover {
            color: $color-primary-50;
        }
    }
}
