@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.Pagination {
    @include mobile {
        justify-content: center;
    }

    &-ListItem {
        &_isDotsLink {
            width: 24px;
        }
    }
}
