@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.QuickAddBySku {
    margin-bottom: 48px;

    &-Title {
        display: flex;
        align-content: center;
        align-items: baseline;
        gap: 15px;
        margin-bottom: 17px;

        p {
            font-size: 20px;
            font-weight: 400;
            line-height: 27px;
            letter-spacing: 0px;
            margin: 0;
        }

        span {
            font-size: 14px;
            font-weight: 400;
            letter-spacing: 0.02em;
            color: $color-neutral-70;
        }
    }

    button {
        width: 215px;
        height: 50px;
    }

    &-Form {
        background-color: #F3F3F3;
        padding: 24px;

        input {
            background: white;
        }

        .Field_type_email,
        .Field_type_tel {
            margin-bottom: 20px;
        }

        form.Form {
            display: grid;
            grid-template-columns: 1fr 215px;
            gap: 10px;
        }

        .Field_type_text {
            margin-bottom: 0;
        }
    }
}
