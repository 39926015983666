a {
    text-decoration: none;
    color: $color-neutral-90;

    @include default-transition();

    &:hover {
        color: $color-primary-50;
    }

    &.Link {
        &:hover,
        &:focus {
            border-block-end: $color-primary-50;
            padding-block-end: 3px;
        }
    }
}
