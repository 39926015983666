@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

@import "../DatePicker/DatePicker.style";

.FieldDatePicker {
    &-Button {
        padding: 8px;
        border-top: 1px solid $color-neutral-40;

        .Button {
            margin-block-start: 8px;
            width: 100%;

            &::before {
                content: '';
                position: absolute;
                inset-block-start: -10px;
                inset-inline: -10px;
                height: 1px;
                background: $color-neutral-40;
            }
        }
    }
}
